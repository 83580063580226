import React from "react"
import Layout from "../components/Layout"
import { PageHeading } from "../components/PageHeading"
import SEO from "../components/SEO"
import tw from "twin.macro"
import { ExclamationCircleOutline } from "heroicons-react"

function Security() {
  return (
    <Layout>
      <SEO
        title={"Security Summary | Pricelist"}
        keywords={`Pricelist, foreign exchange, exchange rates, currency rates, foreign currency, 
        fx rates, vat, value added tax, demand, demand estimate, purchasing power parity, international pricing strategy`}
      />
      <div className="relative px-0 sm:px-6 lg:px-8">
        <div className="mx-auto pb-8 max-w-prose text-lg border-b">
          <PageHeading
            title="Enterprise-level security and privacy for you and your data"
            subtitle="Security Summary"
          />
        </div>
        <div className="content mt-6 mx-auto px-4 max-w-4xl">
          <h2>Application Security</h2>
          <h3>Access Control</h3>
          <p>
            Pricelist does not store any login credentials. Authentication and
            authorization are managed by industry-leading provider Auth0
            (acquired by Okta). All access attempts are stored in an Auth0 audit
            log and suspicious logins are reported to account owners.
          </p>
          <p>
            Pricelist supports authentication using single sign-on (SSO) and
            multi-factor authentication.
          </p>
          <p>
            Pricelist allows customers to use granular role-based access control
            (RBAC) and enforced on the infrastructure level.
          </p>
          <DisclosureBox />
          <h3>Data Security</h3>
          <p>
            Pricelist software and data are hosted on Amazon Web Services (AWS)
            in the United States. Our infrastructure only uses providers and
            vendors that are SOC 2 Type 2 certified.
          </p>
          <p>All data is encrypted at rest and in transit using SSL.</p>
          <p>
            Automatic database backups are performed daily to prevent accidental
            data loss.
          </p>
          <p>
            All client data are deleted from the main store when the account is
            closed.
          </p>

          <h3>Integrations and 3rd Party Data</h3>
          <p>
            Integration API keys to 3rd party services are encrypted at rest and
            in transit.
          </p>
          <h3>Sharing of Data</h3>
          <p>
            Pricelist doesn't share client data with any companies or services
            other than those explicitly approved by the client as part of 3rd
            party integrations. The client can rotate their API keys or revoke
            access to those services at any time.
          </p>

          <h2>Employee and Company Security</h2>
          <p>
            Pricelist team does not have access to client data. Any interaction
            with client data must be pre-cleared in writing by the client.
          </p>
          <p>
            All software code used in the application written by Pricelist is
            subject to mandatory code review by members of the team. The review
            covers, among others, an assessment of code security against OWASP
            Top 10 security risks.
          </p>
        </div>
      </div>
    </Layout>
  )
}

const DisclosureBox = () => (
  <div className="box flex gap-x-2 mx-auto my-8 px-6 py-4 text-white bg-gradient-to-r rounded-md shadow from-blue-800 to-blue-700">
    <div className="w-3/4">
      <h3>Report an issue</h3>
      <p>
        If you discovered a security issue, please contact us at{" "}
        <a
          href="mailto:security@trypricelist.com"
          className="text-white hover:no-underline underline"
        >
          security@trypricelist.com
        </a>{" "}
        to make a disclosure.
      </p>
    </div>
    <div className="flex items-center justify-center w-1/4">
      <ExclamationCircleOutline className="w-1/2 h-auto text-blue-100" />
    </div>
  </div>
)

export default Security
